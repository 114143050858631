<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <template>
      <br />
      <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Enviar Correos' }]" />
      <v-toolbar color="primary" dark card>
        <v-toolbar-title>Enviar Correos </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">Datos Generales</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">Seleccionar Carros</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card class="mb-5">
              <v-autocomplete
                v-model="form.tipo"
                :items="tipos"
                hide-details
                dense
                outline                
                small-chips
                label="Seleccionar tipo"
                required
              />
							<v-text-field
								v-if="form.tipo==='Personalizado'"
                v-model="form.asunto"
                :disabled="processingForm"
                label="Asunto"
                outline
              />
							<ckeditor v-if="form.tipo==='Personalizado'" v-model="editorData" :config="editorConfig"></ckeditor>
            </v-card>
            <v-btn
              color="primary"
              @click="e1 = 2"
              :disabled="form.tipo === '' || form.tipo === null"
            >
              Continuar
            </v-btn>
            <!-- <v-btn color="error">Cancelar</v-btn> -->
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card class="mb-5">
              <v-layout row wrap>
                <v-flex sm6 style="margin-bottom:15px;">
                  <v-text-field
                    v-model="search"
                    box
                    append-icon="search"
                    label="Buscar Carro"
                    clearable
                    hide-details
                  />
                </v-flex>
              </v-layout>
              <v-data-table
                v-model="selected"
                :headers="[
                { text: 'Pedido', value: 'numero_orden' },
                { text: 'Fecha', value: 'created_at' },
                { text: 'Estado', value: 'pago.estado_pago' },
                { text: 'Detalle' },
                { text: 'Ciudad', value: 'ciudad' },
                { text: 'Cliente', value: 'nombre' },
                { text: 'Etapa Actual', value: 'etapaActual.nombre' },
                { text: 'N° Boleta', value: 'pago.num_documento' },
                { text: 'Courier', value: 'courier_nombre' },
                { text: 'N° Sgmto', value: 'num_seguimiento' }
                ]"
                :items="carros"
                :search="search"
                :rows-per-page-items="[10, 25, 50, 100]"
                select-all
                class="elevation-1"
              >
                <template v-slot:items="props">
                  <tr :active="props.selected" @click="props.selected = !props.selected">
                    <td>
                      <v-checkbox :input-value="props.selected" primary hide-details></v-checkbox>
                    </td>
                    <td class="px-3">
                  {{ props.item.numero_orden }}
                </td>
                <td class="px-3">
                  {{ props.item.created_at }}
                </td>
                <td class="px-3">
                  <v-chip
                    v-if="props.item.pago.estado_pago === 'PAGADO'"
                    small
                    class="text-xs-center"
                    color="success"
                    text-color="white"
                    style="letter-spacing:1.8px; font-weight:550;"
                  >
                    {{ props.item.pago.estado_pago }}
                  </v-chip>
                  <v-chip
                    v-else-if="props.item.pago.estado_pago === 'TRANSACCION FALLIDA'"
                    small
                    color="error"
                    text-color="white"
                  >
                    Trans. Fallida
                  </v-chip>
                  <v-chip v-else small color="error" text-color="white">
                    {{ props.item.pago.estado_pago }}
                  </v-chip>
                </td>
                <td class="px-3">
                  <v-tooltip right min-width="280">
                    <template v-slot:activator="{ on }">
                     <v-chip color="blue darken-1" text-color="white" dark v-on="on" small>
                        +  Información
                      </v-chip>
                    </template>
                    <span style="font-size:16px;line-height:36px;"
                      >N° Orden: {{ props.item.numero_orden }}
                      <br />
                      Etapa: {{ props.item.etapaActual.nombre }}
                      <br />
                      Estado de pago: {{ props.item.pago.estado_pago }}
                      <br />
                      N° de boleta: {{ props.item.pago.num_documento }}
                      <br />
                      Forma de pago: {{ props.item.pago.tipo }}
                      <br />
                      Cod. autorización: {{ props.item.pago.codigo_autorizacion }}
                      <br />
                      Courier: {{ props.item.courier_nombre }}
                      <br />
                      N° de Seguimiento: {{ props.item.num_seguimiento }}
                      <br />
                      Peso total: {{ props.item.peso_total }} kgs.
                    </span>
                  </v-tooltip>
                </td>
                <td class="px-3">
                  {{ props.item.ciudad }}
                </td>
                <td class="px-3" v-if="props.item.nombre">
                  <v-tooltip right max-width="300">
                    <template v-slot:activator="{ on }">
                      <v-chip color="blue darken-1" text-color="white" dark v-on="on" small>
                        <v-avatar>
                          <v-icon>account_circle</v-icon>
                        </v-avatar>
                        {{ props.item.nombre }} {{ props.item.apellidos }}
                      </v-chip>
                    </template>
                    <span style="font-size:16px;line-height:36px;"
                      >Nombre: {{ props.item.nombre }} {{ props.item.apellidos }}
                      <br />
                      E-mail: {{ props.item.cliente.email }}
                      <br />
                      Teléfono: {{ props.item.telefono }}
                      <br />
                      Dirección: {{ props.item.direccion }}
                      <br />
                      Ciudad: {{ props.item.ciudad }}
                    </span>
                  </v-tooltip>
                </td>
                <td class="px-3" v-else>
                  {{ props.item.cliente.nombre }}
                </td>
                <td class="px-3">
                  {{ props.item.etapaActual.nombre }}
                </td>
                <td class="px-3">{{ props.item.pago.num_documento }}</td>
                <td class="px-3">
                  {{ props.item.courier_nombre }}
                </td>
                <td class="px-3" style="text-align:center;">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" v-if="props.item.num_seguimiento > 0" color="green">
                        check_circle
                      </v-icon>
                    </template>
                    <span>N°: {{ props.item.num_seguimiento }} </span>
                  </v-tooltip>
                </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>

            <v-btn color="primary" @click="submitEnviarCorreos" :loading="btn_loading">
              Enviar email
            </v-btn>
            <v-btn @click="e1 = 1" color="error">Volver</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-container>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { baseUrlAPI } from "@/config/api";
import axios from "axios";
const HOST = baseUrlAPI;

export default {
  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    NotPermission: () => import("@/views/errors/NotPermission")
  },
  data() {
    return {
      btn_loading:false,
      e1: 0,
      dialog: false,
			editorData: '<p>Ingrese el contenido del email.</p>',
      editorConfig: {
        // The configuration of the editor.
      },
      selected: [],
      formErrors: {},
      errorItems: [],
      dialog: false,
      errorMessage: "",
      pagination: {
        sortBy: "name"
      },
      errorMessage: "",
      name: "",
      search: "",
      item1: "",
      items: [],
      producto: "",
      productos: [],
      cantidad: "",
      nombre: "",
      form: {
        asunto: "",
        contenido: "",
				tipo:'',
        carrosId: []
      },
      tipos: [
				"Abandonado",
    		"Retiro",
    		"Pendiente",
    		"Personalizado"
			],
      mov_tipos: [
        { id: 1, nombre: "Entrada" },
        { id: 2, nombre: "Salida" },
        { id: 3, nombre: "Ajuste de Inventario" }
      ],
      validForm: true,
      processingForm: false,
      rules: {
        porcentaje: [v => (0 < v && v < 81) || "El valor del porcentaje tiene que ser de 1 a 80"]
      }
    };
  },
  computed: {
    ...mapState({
      carros: state => state.carts.carros,
      loadingCarros: state => state.carts.loadingCarros
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    }),
  },
  mounted() {
    this.getCarros();
  },
  methods: {
    ...mapActions({
      createNotification: "carts/createNotification",
      getCarros: "carts/getCarros",
    }),
    async submitEnviarCorreos() {
      this.btn_loading = true;
      this.form.carrosId = this.selected.map(item => {
        return item.id;
      });
      try {
				if(this.form.tipo === 'Personalizado') {
					this.form.contenido = this.editorData
				}
        const data = this.form;
        const res = await this.createNotification({ data });
				this.selected = []
				this.form = {
        	asunto: "",
        	contenido: "",
					tipo:'',
        	carrosId: []
      	};
				this.e1 = 1
      } catch (error) {
        this.errorMessage = error.response.data.message;
        this.formErrors = error.response.data.errors || {};
        console.log(formErrors);
      }
      this.btn_loading = false;
    }
  }
};
</script>
<style scoped>
.wrapper {
  width: 700px;
  overflow: hidden;
}
.first {
  width: 200px;
  float: left;
}
.second {
  float: left;
  margin-left: 30px;
}
</style>
